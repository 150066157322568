import React, {Component} from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="text-center">
                <img src="/assets/logo.png" alt="CF Development Logo"/>
            </div>
        );
    }
}
